import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'


Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$EventBus = new Vue()



new Vue({
  el: '#app',
  router,
  render: h => h(App),
  components:{
    App
  }

}).$mount('#app');
